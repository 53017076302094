import React from "react";

const Astro = () => (
  <svg
    id="astro"
    width="459"
    height="385"
    viewBox="0 0 459 385"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="185.989"
      y="108.667"
      width="94.0838"
      height="137.623"
      rx="15.5"
      transform="rotate(25.714 185.989 108.667)"
      fill="#B6B6B6"
      stroke="#3D4351"
    />
    <rect
      x="173.374"
      y="106.939"
      width="96.7199"
      height="132.521"
      rx="15.5"
      transform="rotate(26.5268 173.374 106.939)"
      fill="#E3E3E3"
      stroke="#3D4351"
    />

    <g id="back-leg">
      <path
        d="M37.032 321.686C21.5249 307.855 41.7461 291.229 53.7951 284.644L95.5016 282.345L95.7073 304.358C82.6102 315.897 52.5391 335.516 37.032 321.686Z"
        fill="#B6B6B6"
        stroke="#3D4351"
      />
      <path
        d="M37.7849 317.135C22.2854 303.605 42.1305 286.93 53.9906 280.283L95.2593 277.566L95.6955 299.359C82.8501 310.922 53.2843 330.664 37.7849 317.135Z"
        fill="white"
        stroke="#3D4351"
      />
      <path
        d="M96.2756 278.951C96.4168 281.96 94.1639 284.924 90.1178 287.247C86.0973 289.555 80.4333 291.147 74.0811 291.445C67.7289 291.743 61.9407 290.689 57.7218 288.767C53.476 286.832 50.9556 284.093 50.8144 281.083C50.6733 278.074 52.9262 275.11 56.9723 272.787C60.9927 270.479 66.6568 268.887 73.009 268.589C79.3612 268.291 85.1493 269.345 89.3683 271.268C93.614 273.202 96.1345 275.941 96.2756 278.951Z"
        fill="white"
        stroke="#3D4351"
      />
      <path
        d="M82.9551 284.562C100.257 282.072 98.9174 264.029 96.0848 255.318C96.0848 255.318 110.483 258.275 119.299 256.138C126.597 254.368 129.034 254.514 138.29 247.76C147.546 241.006 153.38 234.378 142.876 220.281C132.372 206.184 110.992 201.623 89.0603 203.514C79.2326 204.361 73.2555 205.5 64.627 209.918C54.2949 215.208 36.5898 234.496 45.9778 260.249C55.3658 286.002 61.3276 287.675 82.9551 284.562Z"
        fill="white"
        stroke="#3D4351"
      />
    </g>

    <g id="front-leg">
      <path
        d="M167.169 376.41C146.418 377.48 149.104 351.44 153.041 338.285L181.078 307.323L196.704 322.831C195.505 340.245 187.92 375.339 167.169 376.41Z"
        fill="#B6B6B6"
        stroke="#3D4351"
      />
      <path
        d="M164.533 373.94C143.782 375.01 146.469 348.969 150.406 335.815L178.443 304.853L194.068 320.361C192.87 337.774 185.284 372.869 164.533 373.94Z"
        fill="white"
        stroke="#3D4351"
      />
      <path
        d="M180.828 306.17C182.856 308.399 183.009 312.118 181.372 316.488C179.746 320.829 176.396 325.666 171.692 329.945C166.987 334.223 161.855 337.1 157.379 338.309C152.875 339.525 149.187 339.02 147.16 336.792C145.132 334.563 144.979 330.843 146.616 326.474C148.242 322.133 151.592 317.296 156.296 313.017C161.001 308.738 166.133 305.861 170.609 304.653C175.113 303.437 178.801 303.941 180.828 306.17Z"
        fill="white"
        stroke="#3D4351"
      />
      <path
        d="M174.429 318.574C185.994 305.234 171.588 292.461 163.414 288.13C163.414 288.13 175.939 280.161 180.834 272.417C184.887 266.006 186.757 264.4 188.729 252.997C190.701 241.595 190.279 232.687 172.754 229.836C155.229 226.986 130.203 240.731 122.023 251.381C119.541 255.253 112.723 264.116 108.694 277.284C105.264 288.494 105.615 314.814 130.518 326.907C155.421 338.999 159.756 335.5 174.429 318.574Z"
        fill="white"
        stroke="#3D4351"
      />
    </g>

    <ellipse
      cx="134.48"
      cy="236.027"
      rx="40.9027"
      ry="15.9932"
      transform="rotate(20.5886 134.48 236.027)"
      fill="white"
    />

    <g id="back-arm">
      <path
        d="M106.925 79.4396C110.286 81.2833 109.055 94.9574 107.925 101.085C101.271 106.892 90.5565 115.256 87.5308 115.182C83.7487 115.089 78.2921 108.13 74.3413 103.25C70.3904 98.3691 66.7071 89.5537 71.2023 79.1529C75.6976 68.7521 85.8881 66.6448 89.5588 68.3767C92.7115 69.8642 97.6291 82.9257 97.6291 82.9257C97.6291 82.9257 100.415 75.8676 106.925 79.4396Z"
        fill="white"
        stroke="#3D4351"
      />
      <path
        d="M111.996 100.53C113.178 102.623 112.761 105.478 110.912 108.486C109.072 111.476 105.872 114.508 101.733 116.844C97.595 119.18 93.3452 120.354 89.8344 120.383C86.3038 120.412 83.6443 119.293 82.463 117.2C81.2817 115.108 81.6979 112.252 83.5476 109.245C85.3869 106.254 88.5874 103.222 92.7258 100.886C96.8643 98.5503 101.114 97.3771 104.625 97.3478C108.155 97.3183 110.815 98.4374 111.996 100.53Z"
        fill="white"
        stroke="#3D4351"
      />
      <path
        d="M87.3112 115.99C89.1933 107.685 102.027 103.045 108.208 101.763C113.755 106.587 126.855 116.767 134.88 118.894C144.912 121.552 147.306 112.985 161.043 124.131C178.103 137.973 172.431 160.153 157.207 166.037C141.983 171.92 113.802 155.18 110.22 152.137C106.639 149.093 84.9584 126.372 87.3112 115.99Z"
        fill="white"
        stroke="#3D4351"
      />
    </g>

    <path
      d="M139.843 133.892C142.494 127.451 150.57 120.744 154.277 118.196C161.413 113.058 185.866 120.496 200.874 127.921C215.882 135.346 227.152 133.696 233.894 153.322C235.721 158.639 230.174 178.811 227.248 182.448C223.591 186.995 207.541 213.527 205.026 222.673C202.511 231.818 195.583 246.128 186.068 249.449C176.554 252.77 144.006 236.012 129.933 228.307C115.861 220.601 104.864 205.622 105.465 195.325C105.941 187.19 120.708 174.211 126.965 163.945C133.222 153.678 136.53 141.943 139.843 133.892Z"
      fill="white"
      stroke="#3D4351"
    />
    <rect
      x="151.257"
      y="147.707"
      width="47.2624"
      height="46.079"
      rx="11.5"
      transform="rotate(28.0468 151.257 147.707)"
      fill="#E3E3E3"
      stroke="#3D4351"
    />
    <rect
      x="146.098"
      y="147.676"
      width="47.2624"
      height="46.079"
      rx="11.5"
      transform="rotate(28.0468 146.098 147.676)"
      fill="white"
      stroke="#3D4351"
    />
    <circle
      cx="154.971"
      cy="195.743"
      r="2.5"
      transform="rotate(4.3637 154.971 195.743)"
      fill="white"
      stroke="#3D4351"
    />
    <circle
      cx="148.22"
      cy="192.22"
      r="2.5"
      transform="rotate(4.3637 148.22 192.22)"
      fill="white"
      stroke="#3D4351"
    />
    <circle
      cx="161.723"
      cy="199.267"
      r="2.5"
      transform="rotate(4.3637 161.723 199.267)"
      fill="white"
      stroke="#3D4351"
    />

    <path
      d="M229.903 144.722C227.777 150.383 221.702 154.06 213.4 155.316C205.116 156.569 194.731 155.384 184.222 151.437C173.713 147.489 165.116 141.543 159.706 135.146C154.283 128.735 152.132 121.968 154.259 116.307C156.385 110.646 162.46 106.968 170.762 105.713C179.046 104.46 189.432 105.644 199.941 109.592C210.449 113.54 219.046 119.485 224.456 125.883C229.879 132.294 232.03 139.061 229.903 144.722Z"
      fill="white"
      stroke="#3D4351"
    />

    <g id="front-arm">
      <path
        d="M210.343 267.21C209.167 263.043 221.157 253.205 226.988 249.263C236.936 250.024 252.159 252.032 254.496 254.528C257.417 257.648 256.132 267.555 255.329 274.603C254.526 281.651 250.342 291.599 238.403 296.215C226.464 300.831 216.696 294.29 215.184 289.962C213.886 286.244 220.514 271.938 220.514 271.938C220.514 271.938 212.621 275.284 210.343 267.21Z"
        fill="white"
        stroke="#3D4351"
      />
      <path
        d="M258.516 257.671C257.886 260.053 255.539 261.849 252.036 262.732C248.553 263.61 244.044 263.547 239.344 262.304C234.645 261.061 230.694 258.887 228.1 256.402C225.492 253.902 224.339 251.181 224.969 248.8C225.599 246.418 227.946 244.622 231.448 243.739C234.932 242.861 239.441 242.924 244.141 244.167C248.84 245.41 252.791 247.584 255.385 250.069C257.993 252.568 259.146 255.29 258.516 257.671Z"
        fill="white"
        stroke="#3D4351"
      />
      <path
        d="M255.321 254.064C247.14 259.126 233.238 252.462 227.31 248.497C226.804 240.208 224.63 221.593 219.988 213.444C214.185 203.257 205.387 208.113 203.487 188.22C201.127 163.514 223.487 150.518 240.284 158.123C257.08 165.728 265.913 201.687 266.298 206.982C266.682 212.278 265.548 247.738 255.321 254.064Z"
        fill="white"
        stroke="#3D4351"
      />
    </g>

    <g id="head">
      <circle
        cx="213.644"
        cy="79.7062"
        r="62.6148"
        transform="rotate(17.0131 213.644 79.7062)"
        fill="#3D4351"
        stroke="#3D4351"
      />
      <circle
        cx="198.341"
        cy="36.2146"
        r="5.95934"
        transform="rotate(17.0131 198.341 36.2146)"
        fill="white"
      />
      <path
        d="M269.084 110.024C267.172 113.674 264.632 116.527 262.096 118.198C259.542 119.88 257.101 120.306 255.281 119.353C253.462 118.399 252.422 116.15 252.351 113.093C252.281 110.056 253.181 106.344 255.094 102.694C257.006 99.0438 259.545 96.1909 262.082 94.5194C264.635 92.837 267.077 92.4116 268.897 93.3648C270.716 94.318 271.756 96.5676 271.826 99.6245C271.896 102.662 270.996 106.374 269.084 110.024Z"
        fill="#C4C4C4"
        stroke="#3D4351"
      />
      <path
        d="M272.441 111.832C270.529 115.482 267.989 118.335 265.453 120.007C262.899 121.689 260.458 122.114 258.638 121.161C256.819 120.208 255.779 117.958 255.708 114.901C255.638 111.864 256.538 108.152 258.451 104.502C260.363 100.852 262.903 97.9995 265.439 96.328C267.992 94.6456 270.434 94.2202 272.254 95.1734C274.073 96.1266 275.113 98.3762 275.184 101.433C275.253 104.47 274.353 108.182 272.441 111.832Z"
        fill="white"
        stroke="#3D4351"
      />
      <circle
        cx="207.181"
        cy="47.1347"
        r="2.97967"
        transform="rotate(17.0131 207.181 47.1347)"
        fill="white"
      />
    </g>
  </svg>
);

export default Astro;
