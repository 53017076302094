import React from "react";
import Astro from "../components/Astro";
import Stars from "../img/stars.svg";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaCodepen } from "@react-icons/all-files/fa/FaCodepen";

const Cord = () => (
  <svg
    id="astro-cord"
    width="459"
    height="385"
    viewBox="0 0 459 385"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M290 113.5C270.105 113.5 254.684 125.039 254.684 146.513L260.5 148C262 131.5 268.105 118 288 118C295.271 118 299.284 117.831 302.538 124.532C304.419 128.404 302.126 133.738 301.079 137.666L301.063 137.725C299.58 143.286 298.129 148.73 300 154.5C303.561 165.479 312.959 174.048 325.051 170.638C339.09 166.678 347.691 153.276 351.023 140.072C353.989 128.318 355.88 114.096 366 106C371.247 101.803 378.521 98.5395 385.616 102.087C392.087 105.322 388.785 114.426 387.237 121.059C383.5 131.5 366 141 366 170C366 187.971 385.181 211.783 400 201C414.431 190.5 435.5 174.5 457 184.5L458.5 180.5C438.5 170.5 421 179.5 398.07 197.227C381.507 205.073 371.422 180.492 370.138 167.918C367.963 146.603 388.419 133.741 392.921 114.447C394.469 107.814 391.771 98.7104 385.3 95.4748C378.205 91.9276 369.02 97.8448 363.774 102.042C353.654 110.138 352.673 121.706 349.707 133.46C346.375 146.664 339.09 161.04 325.051 165C312.959 168.411 307.061 161.979 303.5 151C301.622 145.209 304.274 138.636 305.763 133.054C306.81 129.126 309.103 123.792 307.222 119.92C303.968 113.219 297.271 113.5 290 113.5Z"
      fill="#282C34"
    />
  </svg>
);

const IndexPage = () => (
  <div className="home-page">
    <div className="flex">
      <div className="col-1">
        <h1>Pablo Jurado</h1>
        <p>Software Engineer</p>
        <div className="social-icons">
          <a
            href="https://github.com/pablo-jurado"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/juradopablo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://codepen.io/pablo-jurado/pens/popular/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaCodepen />
          </a>
        </div>
      </div>
      <div className="col-1">
        <img className="stars" src={Stars} alt="space stars" />
        <div className="astro-wrapper">
          <Astro />
        </div>
        <div className="animation-mask">
          <Cord />
          <div className="animated-border"></div>
        </div>
      </div>
    </div>
  </div>
);

export default IndexPage;
